#rooms {
    padding: 0 50px;
}

@media (max-width: 768px) {
    #rooms {
        padding: 0 20px;
    }
}

#rooms .images {
    border-bottom: solid 1px black;
    padding-bottom: 30px;
}

#rooms .images h1 {
    padding: 80px 0 25px;
    font-size: 36px;
}

#rooms .images .container {
    display: flex;
    gap: 5vw;
    justify-content: center;
}

#rooms .images .photos {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 45vw;
}

#rooms .images .photo {
    width: inherit;
    height: 25vw;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    position: relative;
}

#rooms .images .photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

#rooms .images .photo video {
    width: 100%;
    height: 100%;
    display: block;
}

#rooms .images .photo .left-btn,
#rooms .images .photo .right-btn {
    position: absolute;
    color: #F59A0E;
    background-color: white;
    padding: 15px;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.3s;
    z-index: 2;
    top: calc(50% - 25px);
    font-size: 20px;
}

#rooms .images .photo .left-btn {
    left: 20px;
}

#rooms .images .photo .right-btn {
    right: 20px;
}

#rooms .images .photo .right-btn:hover,
#rooms .images .photo .left-btn:hover {
    background-color: #F59A0E;
    color: white;
    cursor: pointer;
}

#rooms .images .container .photo .index {
    position: absolute;
    font-size: 30px;
    color: white;
    bottom: 0;
    left: calc(50% - 25px);
    text-shadow: 1px 1px 2px black;
}

#rooms .images .slider {
    width: 45vw;
    height: 5vw;
    position: relative;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

#rooms .images .slider::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari and Opera */
}

#rooms .images .photos .thumbnails {
    display: flex;
    gap: 1.25vw;
    position: absolute;
    bottom: 0;
}

#rooms .images .photos .thumbnails .thumbnail {
    width: 8vw;
    height: 5vw;
    border-radius: 12px;
    overflow: hidden;

}

#rooms .images .photos .thumbnails .thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

#rooms .images .photos .thumbnails .thumbnail .video {
    filter: blur(6px);
}

#rooms .images .photos .thumbnails .thumbnail .icon {
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    font-size: 30px;
    background-color: #ff914d;
    border-radius: 40%;
    color: white;
    padding: 10px 10px;
}

#rooms .images .photos .thumbnails .mobile {
    display: none
}

@media (max-width: 1024px) {
    #rooms .images .photos .thumbnails .pc {
        display: none
    }

    #rooms .images .photos .thumbnails .mobile {
        display: block
    }
}

#rooms .images .container .map {
    width: 45vw;
    height: calc(30vw + 20px);
}

@media (max-width: 1200px) {
    #rooms .images .container {
        flex-wrap: wrap;
        gap: 30px;
    }

    #rooms .images .photos {
        width: inherit;
    }

    #rooms .images .container .photo {
        width: 80vw;
        height: 50vw;
    }

    #rooms .images .container .slider {
        width: 80vw;
    }

    #rooms .images .container .map {
        width: 80vw;
        height: 50vw;
    }

    #rooms .images .photos .thumbnails {
        gap: 2.8vw;
    }
}

@media (max-width: 900px) {
    #rooms .images .container .slider {
        height: 10vw;
    }

    #rooms .images .container .slider .thumbnail {
        width: 16vw;
        height: 10vw;
    }
}

@media (max-width: 700px) {
    #rooms .images .container .photo {
        width: 90vw;
        height: 56vw;
    }

    #rooms .images .container .slider {
        width: 90vw;
    }

    #rooms .images .container .map {
        width: 90vw;
        height: 56vw;
    }

    #rooms .images .photos .thumbnails {
        gap: 3.5vw;
    }

    #rooms .images .container .photo .index {
        font-size: 20px;
        left: calc(50% - 20px);
    }
}

@media (max-width: 450px) {
    #rooms .images .container .photo {
        width: 100vw;
        height: 62vw;
        border-radius: 0px;
    }

    #rooms .images .container .slider {
        width: 100vw;
        height: 15vw;
    }

    #rooms .images .container .slider .thumbnail {
        width: 24vw;
        height: 15vw;
    }

    #rooms .images .photo .right-btn,
    #rooms .images .photo .left-btn {
        font-size: 4vw;
    }

    #rooms .images .container .photo .index {
        font-size: 15px;
        left: calc(50% - 15px);
    }

}

/* DESC */

#rooms .desc {
    padding: 20px 0;
    border-bottom: solid 1px black;
}

#rooms .desc h2 {
    text-transform: uppercase;
    font-weight: 600;
}

#rooms .desc p {
    margin-top: 10px;
    color: black;
}

/* EQUIPMENTS */

#rooms .equipments {
    padding: 20px 0;
    border-bottom: solid 1px black;
}

#rooms .equipments h2 {
    text-transform: uppercase;
    font-weight: 600;
}

#rooms .equipments .tags {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

#rooms .equipments .tag {
    display: flex;
    align-items: center;
    gap: 8px;
}

#rooms .equipments img {
    width: 24px;
    height: 24px;
}

#rooms .equipments p {
    margin: 0;
    font-size: 16px;
}

/* ROOMS */

#rooms .rooms {
    padding: 20px 0;
}

#rooms .rooms h2 {
    text-transform: uppercase;
    font-weight: 600;
}

#rooms .rooms .container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    max-width: 1280px;
    margin: 0 auto;
}

#rooms .room {
    width: 400px;
    position: relative;
    margin-top: 20px;
}

#rooms .room::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: -20px;
    width: 2px;
    background-color: #d3d3d3;
}

#rooms .room .photo {
    width: inherit;
    height: 300px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
    position: relative;
}

@media (min-width: 1401px) {
    #rooms .room:nth-child(3n)::after {
        display: none;
    }
}

@media (max-width: 1400px) {
    #rooms .rooms .container {
        grid-template-columns: repeat(2, 1fr);
        max-width: 840px;
    }

    #rooms .room:nth-child(2n)::after {
        display: none;
    }
}

@media (max-width: 950px) {
    #rooms .rooms .container {
        grid-template-columns: 1fr;
        max-width: 400px;
    }

    #rooms .room:nth-child(n)::after {
        display: none;
    }
}

@media (max-width: 444px) {
    #rooms .rooms .container {
        max-width: 300px;
    }

    #rooms .room {
        width: 300px;
    }

    #rooms .room .photo {
        height: 225px;
    }
}

#rooms .room .price {
    position: absolute;
    background-color: #FFE4d4;
    border-radius: 15px;
    padding: 2px 8px;
    width: max-content;
    top: 20px;
    left: 10px;
    color: black;
    z-index: 2;
}

#rooms .room .price .value {
    font-weight: 700;
}

#rooms .room .photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

#rooms .room h3 {
    margin-top: 15px;
}

#rooms .room .description {
    color: black;
    position: relative;
    bottom: 4px;
    font-style: italic;
    height: 50px;
}

#rooms .room .availability {
    color: black;
}

#rooms .room .keywords {
    height: 80px;
}

#rooms .room .reservation {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #ff914d;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
}

#rooms .room .reservation:hover {
    cursor: pointer;
    color: white;
    background-color: #ff914d;
}

/* BOOKED */

#rooms .booked .photo img {
    filter: grayscale(1);
}

#rooms .booked .booked-text {
    position: absolute;
    z-index: 2;
    font-size: 60px;
    color: #ff914d;
    text-transform: uppercase;
    font-weight: 600;
    top: calc(50% - 40px);
    left: 75px;
    transform: rotate(-25deg);
    text-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
}

html[lang="es"] #rooms .booked .booked-text {
    left: 35px;
}

@media (max-width: 444px) {
    #rooms .booked .booked-text {
        top: calc(50% - 30px);
        left: 60px;
        font-size: 45px;
    }
    html[lang="es"] #rooms .booked .booked-text {
        left: 25px;
    }
}

#rooms .booked .availability {
    opacity: 0;
}

#rooms .booked .blocked, #rooms .recap .blocked {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #666666;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
    text-decoration: line-through;
    text-decoration-thickness: 0.2rem;
}

/* APART */

#rooms .rooms .apart .promotion {
    color: #666666;
    text-decoration: line-through;
    text-decoration-thickness: 0.2rem;
}

/* ENTIRE */

#rooms .entire .keywords {
    height: fit-content
}

#rooms .recap {
    padding-top: 40px;
    max-width: 500px;
    width: 80vw;
    margin: auto;
}

#rooms .recap h3 {
    color: #ff914d;
    font-size: 22px;
    font-weight: 700;
}

#rooms .recap .price {
    color: black;
}

#rooms .recap .promo-container {
    display: flex;
    gap: 10px;
}

#rooms .recap .promo-container .price1 {
    color: #666666;
    text-decoration: line-through;
    text-decoration-thickness: 0.2rem;
}

#rooms .recap .promo-container .promo {
    color: white;
    background-color: red;
    padding: 2px 4px;
    border-radius: 10px;
    font-size: 15px;
}

#rooms .recap span {
    font-weight: 700;
}

#rooms .recap .availability {
    margin-top: 20px;
    color: black;
}

#rooms .recap .reservation {
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #ff914d;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
}

#rooms .recap .reservation:hover {
    cursor: pointer;
    color: white;
    background-color: #ff914d;
}

/* POPUP */

#rooms .popup {
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 900;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(10px);
    overflow-y: auto;
    padding-top: 75px;
}

#rooms .none {
    display: none;
}

#rooms .popup .icon {
    font-size: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

#rooms .popup .text {
    color: black;
    font-size: 20px;
    width: 400px;
}

#rooms .popup .text1 {
    color: black;
    font-size: 20px;
}

#rooms .popup input {
    padding: 10px 15px;
    margin: 10px 0;
    border: 1px solid #ff914d;
    outline: none;
    border-radius: 8px;
    box-sizing: border-box;
    display: block;
    height: 40px;
    color: #666666;
    font-family: 'Poppins';
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: white;
}

#rooms .popup>div {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 50px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
    border: #ff914d solid 1px;
    margin: 0 20px 60px 20px;
    z-index: 999;
    height: fit-content;
    max-height: calc(100vh - 70px);
    overflow-y: auto;
}

@supports (-webkit-touch-callout: none) {
    #rooms .popup>div {
        max-height: calc(100vh - 160px);
    } 
 }

#rooms form>div {
    display: flex;
    gap: 10px;
}

#rooms .popup form>div input {
    width: 195px;
    margin: 0;
}

#rooms form>input,
#rooms form>div {
    width: 400px;
}

#rooms .popup input::placeholder {
    font-size: 16px;
    font-weight: 500;
    color: #666666;
    font-family: 'Poppins';
}

#rooms .popup button {
    border: none;
    padding: 10px 15px;
    background-color: #ff914d;
    color: white;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
}

#rooms .popup .name {
    color: #ff914d;
    font-weight: 600;
}

#rooms .popup .here {
    color: blue;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 600px) {
    #rooms .popup form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
    }
    #rooms .popup input {
        margin: 0;
    }
    #rooms .popup form>div {
        flex-wrap: wrap;
        width: 200px;
    }

    #rooms .popup input {
        width: 195px;
    }

    #rooms .popup .text {
        width: 300px;
        text-align: center;
    }
}